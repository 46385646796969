import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import BgImg from "../components/BgImg"
import TestimonialCard from "../components/TestimonialCard"

const Testimonials = props => {
  const testimonials = props.data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="Testimonials" />
      <Container
        title="Testimonials"
        style={{
          background: `#52575a`,
          color: `white`,
          paddingBottom: "40px",
        }}
        whitetitle
      >
        {testimonials.map(({ node }, index) => {
          const client = node.frontmatter.client
          const location = node.frontmatter.location
          const body = node.rawMarkdownBody

          return (
            <TestimonialCard
              title={client}
              location={location}
              text={body}
              key={index}
            />
          )
        })}

        <BgImg />
      </Container>
    </Layout>
  )
}

export default Testimonials

export const testimonialListQuery = graphql`
  query testimonialListQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "testimonials" } } }
    ) {
      edges {
        node {
          rawMarkdownBody
          frontmatter {
            client
            location
          }
        }
      }
    }
  }
`
