import React from "react"
import styled from "styled-components"

const CardContainer = styled.div`
  -webkit-box-shadow: 0 0 15px 15px rgba(87, 87, 89, 1);
  box-shadow: 0 0 15px 15px rgba(87, 87, 89, 1);

  border-radius: 25px;
  max-width: 700px;
  width: 100%;
  padding: 25px;
  background-color: #f9feff;
  z-index: 15;
  color: black;
  margin-top: 35px;

  p,
  h2,
  .subheading {
    color: rgb(87, 89, 89);
    text-align: left;
    margin: 0;
    padding: 10px 0;
    max-width: 100%;
  }

  h2 {
    font-size: 1.8em;
  }

  .subheading {
    padding: 1px 0 10px;
    font-style: italic;
    font-size: 1em;
  }

  p {
    font-size: 1em;
    line-height: 1.5;
  }
`

const TestimonialCard = ({ title, location, text }) => (
  <CardContainer>
    <h2>{title}</h2>
    {location && <h5 className="subheading">{location}</h5>}
    <p>{text}</p>
  </CardContainer>
)

export default TestimonialCard
